import client from "./client";

const getGuideBySlug = (slug) => {
  return client
    .get(`/guide/is/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getGuide = (id) => {
  return client
    .post(`/guide/${id}`, filter)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getGuides = (filter) => {
  return client
    .get(`/guides`, filter)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const createGuide = (data) => {
  return client
    .post("/guide", data)
    .then((response) => {
      return response;
    })
    .catch((error) => Promise.reject(error.response.data));
};

const updateGuide = (data) => {
  return client
    .put(`/guide/${data.id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => Promise.reject(error.response.data));
};

const deleteGuides = (data) => {
  return client
    .put(`/guides`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const guideAPI = {
  getGuide,
  createGuide,
  updateGuide,
  deleteGuides,
  getGuides,
  getGuideBySlug,
};

export default guideAPI;
