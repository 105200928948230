<template>
	<div>

		<a-row type="flex" :gutter="24">
			<a-col :span="12" class="mb-24">
				<a-button type="primary" @click="$router.push('/guides/create')">New Guide</a-button>
			</a-col>
		</a-row>

		<a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
			<div class="mx-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" class="text-right">
						<a-button v-if="selectedRowKeys.length > 0" type="danger" @click="deleteSelectedRows()"
							style="margin-right: 10px;">
							<a-icon type="delete" />
						</a-button>
						<a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
							@input="debouncedSearch" />
					</a-col>
				</a-row>
			</div>
			<a-table class="mt-20" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:columns="columns" :data-source="guides" rowKey="id"
				:pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
				:loading="isNotLoading">
				<!-- Premium Column -->
				<template slot="premium" slot-scope="premium">
					<a-button v-if="premium" shape="circle" size="small" class="btn-status border-success mr-5">
						<a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />
					</a-button>
					<a-button v-else shape="circle" size="small" class="btn-status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">{{ premium ? 'Yes' : 'No' }}</span>
				</template>
				<!-- Reviewed Column -->
				<template slot="is_reviewed" slot-scope="is_reviewed">
					<a-button v-if="is_reviewed" shape="circle" size="small" class="btn-status border-success mr-5">
						<a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />
					</a-button>
					<a-button v-else shape="circle" size="small" class="btn-status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">{{ is_reviewed ? 'Yes' : 'No' }}</span>
				</template>
				<!-- Status Column -->
				<template slot="is_active" slot-scope="is_active">
					<a-button v-if="is_active" shape="circle" size="small" class="btn-status border-success mr-5">
						<a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />
					</a-button>
					<a-button v-else shape="circle" size="small" class="btn-status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">{{ is_active ? 'Yes' : 'No' }}</span>
				</template>
				<template slot="status" slot-scope="status">
					<span v-if="status === 'draft'">Draft</span>
					<span v-else-if="status === 'in_review'">In Review</span>
					<span v-else-if="status === 'published'">Published</span>
					<span v-else-if="status === 'hidden'">Hidden</span>
					<span v-else-if="status === 'needs_redo'">Needs Redo</span>
					<span v-else>N/A</span>
				</template>
				<!-- Public Column -->
				<template slot="isPublic" slot-scope="isPublic">
					<a-button v-if="isPublic" shape="circle" size="small" class="btn-status border-success mr-5">
						<a-icon class="m-0 text-success" type="check" style="font-size: 10px;" />
					</a-button>
					<a-button v-else shape="circle" size="small" class="btn-status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">{{ isPublic ? 'Yes' : 'No' }}</span>
				</template>
				<template slot="action" slot-scope="action">
					<a-button type="primary" @click="$router.push(`/guides/edit/${action}`)">
						<a-icon type="edit" />
					</a-button>
				</template>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import debounce from 'lodash/debounce';
import guideAPI from '../../api/guide';
// Table columns
const columns = [
	{
		title: 'NAME',
		dataIndex: 'name',
		sorter: (a, b) => a.name > b.name ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'name' },
	},
	{
		title: 'SUBHEADER',
		dataIndex: 'sub_header',
		sorter: (a, b) => a.sub_header > b.sub_header ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'sub_header' },
	},
	{
		title: 'STATUS',
		dataIndex: 'status',
		sorter: (a, b) => a.status > b.status ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'status' },
	},
	{
		title: 'PUBLIC',
		dataIndex: 'public',
		sorter: (a, b) => a.public === b.public ? 0 : a.public ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'isPublic' },
	},
	{
		title: 'PREMIUM',
		dataIndex: 'premium',
		sorter: (a, b) => a.premium === b.premium ? 0 : a.premium ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'premium' },
	},
	{
		title: 'ACTIVE',
		dataIndex: 'is_active',
		sorter: (a, b) => a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'is_active' },
	},
	{
		title: 'REVIEWED',
		dataIndex: "is_reviewed",
		sorter: (a, b) => a.is_reviewed === b.is_reviewed ? 0 : a.is_reviewed ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'is_reviewed' },
	},
	{
		title: 'ACTION',
		dataIndex: 'slug',
		scopedSlots: { customRender: 'action' },
	},
];

export default {
	data() {
		return {

			// Table columns
			columns,

			// Table Authors
			guides: [],

			// Table page size
			pageSize: 10,

			// Table page skip
			currentPage: 1,

			totalCount: 0,

			// Table search query
			query: '',

			// Table's selected rows
			selectedRowKeys: [],

			isNotLoading: false,

			debouncedSearch: () => { },

		}
	},
	computed: {

		// CSV data array
		csvData() {
			return this.authors.map(item => ({
				"Id": "%23" + item.key,
				"Date": item.date,
				"Status": item.status,
				"Customer": item.customer.name,
				"Product": item.product,
				"Revenue": "$" + item.revenue,
			}));
		}

	},
	methods: {
		async deleteSelectedRows() {
			await guideAPI.deleteGuides({
				"selected_row_keys": this.selectedRowKeys,
			}).then(() => {
				if ((this.selectedRowKeys.length === this.guides.length || this.guides.count === this.selectedRowKeys.length) && this.currentPage > 1) {
					this.handlePageChange(this.currentPage - 1);
				} else {
					this.handlePageChange(this.currentPage);
				}
				this.selectedRowKeys = [];
			});
		},
		async handlePageChange(page) {
			this.currentPage = page;
			const data = await this.getGuides({
				params: {
					"arrangement": "ASC",
					"order": "is_reviewed",
					"limit": this.pageSize,
					"skip": (this.currentPage - 1) * this.pageSize,
				}
			})
			if (Object.keys(data).length !== 0) {
				this.guides = data.guides;
				this.totalCount = data.guides.length > 0 ? data.count : 0;
			} else {
				this.totalCount = 0;
			}
			this.isNotLoading = false;
		},

		// get authors according to filters
		async getGuides(filter) {
			this.isNotLoading = true;
			return guideAPI.getGuides(filter);
		},

		// Event listener for input change on table search field.
		async onSearchChange() {
			if (this.query.length >= 3) {
				const data = await this.getGuides({
					params: {
						"arrangement": "ASC",
						"limit": this.pageSize,
						"skip": 0,
						"order": "is_reviewed",
						"query": this.query,
					}
				})
				this.guides = data.guides;
				if (Object.keys(data).length !== 0) {
					this.totalCount = data.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			} else {
				const originalData = await this.getGuides({
					params: {
						"arrangement": "ASC",
						"limit": this.pageSize,
						"order": "is_reviewed",
						"skip": 0,
					}
				});
				this.guides = originalData.guides;
				if (Object.keys(originalData).length !== 0) {
					this.totalCount = originalData.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			}
		},

		// Event listener for table row selection change.
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},

		// Export table in CSV format.
		csvExport(arrData) {
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += [
				Object.keys(arrData[0]).join("|"),
				...arrData.map(item => Object.values(item).join("|"))
			]
				.join("\n")
				.replace(/(^\[)|(\]$)/gm, "");

			const data = encodeURI(csvContent);
			const link = document.createElement("a");
			link.setAttribute("href", data);
			link.setAttribute("download", "muse-dashboard-csv.csv");
			link.click();
		}
	},
	async mounted() {
		this.debouncedSearch = debounce(this.onSearchChange, 300);
		const data = await this.getGuides({
			params: {
				"arrangement": "ASC",
				"limit": this.pageSize,
				"order": "is_reviewed",
				"skip": (this.currentPage - 1) * this.pageSize,
			}
		})
		if (Object.keys(data).length !== 0) {
			this.guides = data.guides;
			this.totalCount = data.guides.length > 0 ? data.count : 0;
		} else {
			this.totalCount = 0;
		}
		this.isNotLoading = false;
	},
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
	display: flex;
	align-items: center;
}

.table-avatar-info .ant-avatar {
	margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
	font-size: 12px;
}

.btn-status::v-deep .anticon {
	line-height: 0;
}
</style>